@import "../../variables";

#root {
  #designs {
    .ant-table-wrapper {
      .ant-table-row {
        .ant-table-cell:last-child {
          text-align: right;
        }
      }
    }
  }
  #newDesign {
    .ant-upload-list-picture-card-container + .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      display: none;
    }
  }
}


.iconPlaceholder {
  font-size: 40px;
  margin: 0 4px;
  color: #00001018;
}
