@import "../../variables";

#root {
  #downloads {
    .ant-table-row {
      .ant-table-cell:first-child {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
