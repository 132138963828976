
#root {
  h3 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline;
  }
  a {
    h3 {
      color: #0058ff;
    }
  }
}
