$screen-small: 768px;
$screen-regular: 769px;
$screen-large: 1200px;

$bg-light: #f4f7fc;
$bg-dark: #31353e;
$bg-header: #252b39;

$color-light: #ffffff;
$color-semi-light: #d9dcdd;
$color-dark: #252b39;
$color-default: #666666;
$color-disabled: #dededf;

$white: #ffffff;
$blue: #0058ff;
$red: #ef7575;

$padding-default: 40px;
$padding-header: 20px;

$layout-padding: 140px;
$layout-padding-small-screen: 40px;
$layout-padding-sm: 20px;
$margin-small: 10px;

$border-radius: 6px !default;
$box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);

$font-family: "Poppins", sans-serif;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-size-super-large: 42px;
$font-size-large: 26px;
$font-size-regular: 14px;
$font-size-small: 12px;

$logo-height: 30px;
$logo-login-height: 48px;
