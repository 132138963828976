@import "../../variables";

#root {
  .ant-layout-sider-children {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .logo {
      align-items: center;
      padding: 20px 0 0px 24px;
      margin-bottom: 48px;

      img {
        height: $logo-height;
      }

      h1 {
        margin: auto;
        display: inline-block;
        color: #fffc;
        font-weight: $font-weight-semi-bold;
        font-size: 20;
        margin-bottom: 0px;
        padding-left: $margin-small;
        vertical-align: middle;
        small {
          display: block;
          color: #fff6;
          margin-top: 3px;
        }
      }
    }
  }
  .ant-layout-sider-children::-webkit-scrollbar {
    display: none;
  }
  .ant-layout-content {
    padding: 28px 24px;
    overflow-y: scroll;
  }
}
