@import "../../variables";

#root {
  #products {
    .body {
      #product-form {
        .ant-card-body {
          padding-top: 8px;
          .db-images {
            .db-image {
              position: relative;
              img {
                padding: 12px 8px 0;
              }
              .btn-del {
                text-align: center;
                color: #0003;
                width: 100%;
                background-color: #ffffff37;
                padding: 4px 8px;
                cursor: pointer;
                &:hover {
                  color: #beafaf;
                }
              }
              .wrap-normal {
                background-color: #f2f2f7;
              }
              .wrap-delete {
                background-color: #f7e6e6;
                .btn-del {
                  color: #874e4e;
                }
              }
              .del-wrap {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 3;
                font-size: 18px;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.87);
                background-color: #d73d51;
              }
            }
          }
          .labelGreen label {
            color: #3fa23f;
          }
          .labelRed label {
            color: #a25b5b;
          }
        }
      }
    }
  }
  #newproduct {
    .audioPlayer {
      // margin: 10px 0 3px;
      audio {
        width: 100%;
      }
      .filename {
        font-size: 12px;
        color: #a0a0b8;
        text-align: right;
      }
    }
    .audioUpload {
      margin-bottom: 6px;
      display: block;
      div.ant-upload {
        // border: solid red;
        height: 66px;
        width: 100%;
        margin-top: 8px;
        display: block;
        span.ant-upload {
          display: block;
          height: 100%;
          button {
            width: 100%;
            text-align: left;
            display: flex;
            padding-left: 14px;
            align-items: center;
            height: 100%;
            text-transform: none;
            color: rgba(0,0,0,0.45);
            .anticon {
              font-size: 22px;
              color: rgb(206, 219, 230);
              margin-left: 18px;
            }
          }
        }
      }
    }
    .dietaryRow {
        border-collapse: collapse;
      .ant-col-6 {
        border: solid #f2f2f7;
        padding-top: 12px;
      }
    }
    .ctrlGroup {
      background-color: #f2f2f8;
      padding: 12px 12px 20px;
      h3 {
        text-transform: none;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 14px;
        display: block;
      }
      .imgOverride {
        margin-top: 20px;
      }
    }
    .labelSm {
      margin-bottom: 12px;
      .ant-form-item-label {
        padding-bottom: 0;
      }
    }
    .uploadSm {
      margin-top: 16px;
      margin-bottom: 0;

    }
    .mbSm {
      margin-bottom: 5px;
    }
    .instructions {
      // border: solid red;
      background-color: #f2f2f8;
      padding: 16px 12px;
      margin-bottom: 28px;
      .ant-upload-list-picture-card-container + .ant-upload.ant-upload-select.ant-upload-select-picture-card {
        display: none;
      }
    }
    .empty {
      color: #00001066;
      margin-bottom: 12px;
    }
    .instrRow {
      // border: solid red;
      margin-bottom: 20px;
    }
    .instrAdd {
    }
    .instrMove {
      // border: solid red;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .instrBody {
      // border: solid red;
    }
  }
}
