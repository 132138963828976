@import "../../variables";

#root {
  #categories {
    .ant-table-wrapper {
      .ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
        padding: 0 10%;
        .category-detail {
          padding: 12px;
          background-color: #f2f2f5;
          form {
            margin-bottom: 12px;
            .ant-row.ant-form-item:last-child {
              margin-right: 0;
            }
          }
          .empty {
            background-color: #fffc;
            padding: 12px 16px;
            color: #94949a;
            width: 100%;
          }
        }
      }
    }
  }
  #newCategory {
    .ant-upload-list-picture-card-container + .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      display: none;
    }
  }
}


.iconPlaceholder {
  font-size: 40px;
  margin: 0 4px;
  color: #00001018;
}
