@import "../../variables";

#root {
  #generate {
    h4 {
      font-size: .94rem;
      margin-bottom: 16px;
    }
    .dWrap {
      cursor: pointer;
      position: relative;
      .dImg {
        height: 120px;
        width: 100%;
        img {
          object-fit: contain;
        }
      }
      h5 {
        font-size: 0.85rem;
        margin-top: 12px;
      }
      .dCheck {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 1.4rem;
      }
      &:hover {
        border: solid rgb(190, 195, 240) 1px;
      }
    }
    .preview {
      border-top: solid rgb(237, 237, 237) 1px;
      padding-top: 16px;
      margin-top: 6px;
      width: 100%;
    }
  }
}
