@import "../../variables";

#root {
  #retailers {
    .body {
      #retailer-form {
        .ant-card-body {
          padding-top: 8px;
          // padding-bottom: 8px;
        }
      }
    }
  }
  #retailerDesign {
    .controls {
      background-color: #eee;
      // flex: 1;
      // border: solid violet 3px;
      // display: flex;
      // flex-direction: column;
      // align-items: flex-end;
      // width: 100%;
      // justify-content: center;
      padding-left: 32px;
      .formControls {
        // border: solid green 3px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 12px;
        background-color: #0011;
        padding: 10px 12px;
        margin-top: 32px;
        // width: 100%;
        max-width: 400px;
        min-width: 240px;
        width: 300px;
        // min-width: 300px;
        .control {
          border: solid #ddd 1px;
          min-width: 240px;
          max-width: 400px;
          width: 100%;
          padding: 8px 12px 12px;
          background-color: #fff;
          margin-bottom: 3px;
          .title {
            margin-bottom: 4px;
          }
          .blocks {
            margin-top: 12px;
            .block {
              border: solid #aaa 1px;
              padding: 5px 8px;
              border-radius: 2px;
              .blockHeader {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .blockCol {
                  flex: 1;
                }
                .blockCol2 {
                  flex: 4;
                  cursor: pointer;
                  user-select: none;
                  font-weight: 500;
                }
              }
              .blockInner {
                padding: 24px 32px;
                color: #aaa;
              }
            }
          }
        }
      }
    }
    @media (max-width: 906px) {
      .controls {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-left: 0;
      }
    }
  }
  #newRetailer {
    .ant-upload-list-picture-card-container + .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      display: none;
    }
    .body {
      #retailer-form {
        .ant-card-body {
          padding-top: 8px;
          padding-bottom: 8px;
          .absRight {
            position: absolute;
            right: 32px;
            top: 28px;
            background-color: #fff;
            z-index: 9;
            padding-left: 16px;
            .ant-btn {
              border-color: #f2f2f7;
              .anticon {
                font-size: 24px;
                color: #ccccda;
              }
            }
            .ant-btn:focus {
              // color: yellow;
              outline: none;
              border-color: #ccc;
            }
          }
          // .vHidden {
          //   display: none;
          // }
          .db-images {
            .db-image {
              position: relative;
              img {
                padding: 12px 8px 0;
              }
              .btn-del {
                text-align: center;
                color: #0003;
                width: 100%;
                background-color: #ffffff37;
                padding: 4px 8px;
                cursor: pointer;
                &:hover {
                  color: #beafaf;
                }
              }
              .wrap-normal {
                background-color: #f2f2f7;
              }
              .wrap-delete {
                background-color: #f7e6e6;
                .btn-del {
                  color: #874e4e;
                }
              }
              .del-wrap {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 3;
                font-size: 18px;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.87);
                background-color: #d73d51;
              }
            }
          }
          .hours-wrap {
            width: 100%;
            .hours-item {
              border: solid #e3e3e7 1px;
              padding: 12px 16px 16px;
              h4 {
                margin-bottom: 12px;
              }
              .timepickers {
                margin-top: 16px;
                .ant-picker {
                  width: 100%;
                  margin-bottom: 5px;
                }
              }
              .text-success,
              .text-danger {
                display: block;
                padding-top: 16px;
              }
            }
          }
        }
      }
    }
  }
  #retailerProducts {
    .empty {
      color: #0006;
      margin-top: 10px;
    }
    .placeholderCircle {
      // 
    }
    .cardDark {
      background-color: #00104a1a;
    }
  }
}
