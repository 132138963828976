@import "../../variables";

#root {
  #adjustDesign {
    .body {
      overflow-x: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      // background-color: lightblue;
      h4 {
        margin-top: 32px;
      }
      .designArea {
        // background-color: yellow;
        background-image: url("../../assets/transparent.png");
        background-repeat: repeat;
        // background-size: 20px 20px;
        width: 600px;
        height: 600px;
        position: relative;
        .canvas-container {
          // background-color: lightcyan;
          // border: solid red 3px;
          width: 600px;
          height: 600px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 99;
        }
        .imgBg {
          width: 600px;
          height: 600px;
          // background-color: red;
          .bgImg {
            width: 600px;
            height: 600px;
            // background-color: red;
          }
        }
      }
      .controls {
        // border: solid red;
        margin-top: 20px;
      }
    }
  }
}
