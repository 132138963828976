@import "variables";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

#root {
  font-family: "Poppins", sans-serif;
  font-weight: $font-weight-normal;
  font-size: $font-size-regular;

  .body {
    margin: 20px 0;
    // min-height: 90vh;
    // overflow-y: scroll;
  }

  .userTabs {
    button.ant-btn.ant-btn-primary {
      background-color: #2d75b8;
      border-color: #2d75b8;
    }
  }

  .detailView {
    h3 {
      width: 80%;
      margin: 0 10% 5px;
      display: block;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  .col1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    div:nth-child(2) {
      margin-left: 11px;
    }
  }
  
  .statusGreen {
    background-color: rgb(119, 174, 119);
    height: 8px;
    width: 8px;
    border-radius: 4px;
  }

  .ant-btn {
    border-radius: 4px;
    text-transform: uppercase;

    &.ant-btn-sm {
      font-size: 12px;
    }

    &.ant-btn-primary {
      background-color: $blue;
      border-color: $blue;
      &[disabled] {
        color: #fffa;
        opacity: 0.7;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffffff;
    border-color: #d9d9d9;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: $blue;
  }

  .ant-card {
    height: 100%;
    padding: 8px 12px;
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    h1 {
      font-size: 20px;
      font-weight: $font-weight-semi-bold;
      line-height: 1.2;
      text-align: left;
      margin-bottom: 24px;
    }

    .ant-card-head {
      border-bottom: none;

      .ant-card-head-title {
        font-size: 18px;
        font-weight: $font-weight-semi-bold;
      }
    }
  }
  .text-success {
    color: #8aba97;
  }
  .text-danger {
    color: #e59494;
  }
  .ant-divider {
    margin-bottom: 28px;
    h3 {
      margin-bottom: 0;
      font-weight: 600;
    }
  }
}
