@import "../../variables";

#root {
  #users {
    .body {
      #listing-form {
        .ant-card-body {
          padding-top: 8px;
          .ant-divider {
            margin-bottom: 28px;
            h3 {
              margin-bottom: 0;
              font-weight: 600;
            }
          }
          .db-images {
            .db-image {
              position: relative;
              img {
                padding: 12px 8px 0;
              }
              .btn-del {
                text-align: center;
                color: #0003;
                width: 100%;
                background-color: #ffffff37;
                padding: 4px 8px;
                cursor: pointer;
                &:hover {
                  color: #beafaf;
                }
              }
              .wrap-normal {
                background-color: #f2f2f7;
              }
              .wrap-delete {
                background-color: #f7e6e6;
                .btn-del {
                  color: #874e4e;
                }
              }
              .del-wrap {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 3;
                font-size: 18px;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.87);
                background-color: #d73d51;
              }
            }
          }
          .hours-wrap {
            width: 100%;
            .hours-item {
              border: solid #e3e3e7 1px;
              padding: 12px 16px 16px;
              h4 {
                margin-bottom: 12px;
              }
              .timepickers {
                margin-top: 16px;
                .ant-picker {
                  width: 100%;
                  margin-bottom: 5px;
                }
              }
              .text-success,
              .text-danger {
                display: block;
                padding-top: 16px;
              }
            }
          }
        }
      }
      .userTabs {
        // border: solid red;
        margin-bottom: 14px;
      }
    }
  }
}
