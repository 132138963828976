@import "../../variables";

#root {
  #variants {
    .body {
      .header {
        // border: solid red 2px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 22px;
        overflow: hidden;
        .ant-select {
          min-width: 120px;
        }
      }
      #variant-form {
        .ant-card-body {
          // padding-top: 8px;
          .preview {
            // border: solid rgb(195, 195, 195) 1px;
            width: 100%;
            min-height: 100px;
            aspect-ratio: 1;
            margin-top: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: rgb(192, 130, 130);
            .preview-inner {
              width: 80%;
              aspect-ratio: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              background-color: white;
              p {
                color: #bbb;
              }
            }
          }
        }
      }
      .ant-table-cell {
        .preview {
          // border: solid rgb(195, 195, 195) 1px;
          width: 80px;
          // min-height: 100px;
          aspect-ratio: 1;
          margin-top: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: rgb(192, 130, 130);
          .preview-inner {
            // border: solid rgb(195, 195, 195) 1px;
            width: 80%;
            aspect-ratio: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: white;
            p {
              color: #bbb;
            }
          }
        }
      }
    }
  }
}

.detailWrap {
  // background-color: #f4f4f9;
  background-color: #fff;
  border: solid #00002210 1px;
  padding: 4px 20px 16px;
  width: 88%;
  margin: 0 auto;
}
