@import "../../variables";

#root {
  .ant-breadcrumb {
    .ant-breadcrumb-separator {
      margin: 0 10px;
      .ant-separator {
        color: #b0b4ce;
        font-size: 1.2rem;
      }
    }
    h3 {
      font-size: 1.23rem;
      margin-bottom: 0;
    }
    span:last-child span h2,
    span:last-child span h3 {
      color: #0058ff;
    }
  }
}
