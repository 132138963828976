@import "../../variables";

#root {
  #home {
    h1 {
      // text-align: center;
    }
    h4 {
      color: #666;
      // font-size: .96rem;
    }
    h5 {
      color: #999;
      // font-size: .96rem;
    }
  }

  #dispenserView {
    .restock-log, .refill-log {
      margin-top: 30px;
    }
    h5 {
      color: #999;
      // font-size: .96rem;
    }
  }
}

#restock {
  .ant-row {
    margin-bottom: 16px;
  }
  .footer {
    padding-bottom: 0;
    .ant-row {
      margin-bottom: 0;
    }
  }
  .required {
    color: #ff4d4f;
    margin-right: 4px;
    font-size: 14px;
  }
}

.empty {
  color: #999;
  padding: 20px 0;
}

.avatar-placeholder {
  width: 80%;
  background-color: rgb(240, 240, 240);
  aspect-ratio: 1;
  border-radius: 5px;
}

