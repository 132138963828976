@import "../../variables";

#root {

  #login {
    background-color: $bg-header;
    // margin-right: -$layout-padding;
    // margin-left: -$layout-padding;
    position: relative;
    margin: -28px -24px;
    
    .background {
      content: "";
      width: 50%;
      position: absolute;
      height: 100vh;
      top: 0px;
      bottom: 0;
    }

    .logo {
      font-weight: $font-weight-semi-bold;

      img,
      h1 {
        display: block;
      }

      h1 {
        color: $color-light;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-large;
        padding: 8px 0;
      }

      img {
        height: $logo-login-height;
      }
    }

    .wrapper {
      height: 100vh;

      .right-side,
      .left-side {
        align-items: middle;
      }

      .left-side {
        .title {
          margin: auto;
          vertical-align: middle;
          margin-left: $layout-padding;
          padding-right: 107px;

          h1 {
            text-align: left;
            color: $white;
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-super-large;
          }
        }
      }

      .right-side {
        padding-left: 107px;
        padding-right: $layout-padding;

        h1 {
          color: $white;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 20px;

          small {
            font-size: $font-size-regular;
            font-weight: $font-weight-normal;
          }
        }

        h2 {
          color: $white;
          margin-top: 12px;

          small {
            color: #fffa;
          }
        }

        .ant-form {
          &.ant-form-vertical {
            margin-top: 24px;

            .ant-form-item {
              .ant-form-item-label {
                label {
                  color: $white;
                  font-weight: $font-weight-semi-bold;
                }
              }

              .ant-checkbox-wrapper {
                color: $white;
              }

              .login-form-forgot {
                float: right;
              }
            }
          }
        }

        .ant-checkbox-group {
          color: $white;
          margin-bottom: 10px;

          .ant-checkbox-wrapper {
            color: $white;
            padding: 4px;
            background-color: rgba(0, 88, 255, 0.4);
            border-radius: $border-radius;
            font-size: 12px;
            margin-bottom: 9px;

            .ant-checkbox-inner {
              background-color: transparent;
              border: none;
              width: 0px;
              height: 0px;
            }

            .ant-checkbox-checked {
              padding-left: 5px;

              .ant-checkbox-inner {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
}
